import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { fontSizes, modules } from "../structure/theme"
import { ProductPriceMemo } from "./productPrice.component"
import { ButtonLink } from "../structure/theme/button.component"
import breakpoint from "styled-components-breakpoint"
import { ProductPictoMemo } from "./productPicto.component"
import { useCampaignProduct } from "../campaign/campaign.utils"
import RichContent from "../structure/theme/richContent.component"
import { useProductClickTracking } from "../thirdParties/gtm/tagManager"
import { usePrice } from "./product.utils"
import { Promo as StyledPromo, Title as StyledTitle } from "./productCard.styled"
import { useTranslation } from "../translation/translate.component"
import { flexCenter } from "../structure/theme/styles.component"

const LinkDiscover = styled(ButtonLink).attrs({
  textColor: modules(`product.card.button.color`),
  backgroundColor: modules(`product.card.button.backgroundColor`),
})`
  margin-top: 20px;
  max-width: 100%;
  width: 170px;
  display: inline-flex;
  padding: 0;
`

const Product = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  flex-direction: column;

  ${breakpoint(`medium`)`
    height: 100%;
    flex-direction: row;
    flex: 1;
  `}
`

const ProductElement = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: flex-start;

  ${breakpoint(`medium`)`
    &:nth-child(1) {
      flex: 4;
      align-items: flex-start;
      padding-left: 20px;
    }
    
    &:nth-child(2) {
      flex: 5;
    }
    
    &:nth-child(3) {
      flex: 3;
      align-items: flex-end;
      padding-right: 20px;
      justify-content: flex-start;
    }
  `}
`

const Title = styled(StyledTitle)`
  margin-top: 0;
  text-align: center;
  padding: 0 10px;

  ${breakpoint(`medium`)`
    padding: 0;
    text-align: left;
  `}
`

const Promo = styled(StyledPromo)`
  width: 120px;
  height: 25px;
  font-size: ${fontSizes(`small`)};
  margin-bottom: 10px;
`

const Description = styled.div`
  padding: 0 20px;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
`

const Wrapper = styled.div`
  ${flexCenter};
  padding-top: 10px;
`

const PriceWrapper = styled.div``

export default function ProductRowCard ({
  product,
  withLogo = false,
  withPrice = false,
  withUniverseDescription = false,
  withReducedPrice = false,
  universesColor = false,
  position = 0,
  onClick,
}) {
  const { t } = useTranslation()

  const [campaignProduct] = useCampaignProduct(product)

  const trackProductClick = useProductClickTracking(product, position)

  const { name, slug, universes, universDescription, housingType } = product

  const { betterPrice } = usePrice(campaignProduct)

  function internalOnClick (event) {
    trackProductClick()
    onClick(event, product)
  }

  return (
    <Product
      data-testid={`${slug
        .split(`/`)
        .slice(-2, -1)[0]
        .replace(/-/g, `_`)}_product_card`}
    >
      <ProductElement>
        {withPrice && withReducedPrice && betterPrice && (
          <Promo data-testid="special_offer">{t(`common:product.section_product_card_special_offer`)}</Promo>
        )}
        <Title data-testid="product_card_name">{name}</Title>
        {withLogo && (
          <Wrapper>
            <ProductPictoMemo
              card
              housingType={housingType}
              universes={universes}
              universesColor={universesColor}
              data-testid="product_card_logo"
              size={50}
            />
          </Wrapper>
        )}
      </ProductElement>

      <ProductElement>
        {withUniverseDescription && (
          <Description>
            <RichContent content={universDescription} styledBullets data-testid="product_card_description" />
          </Description>
        )}
      </ProductElement>

      <ProductElement>
        {withPrice && (
          <PriceWrapper>
            <ProductPriceMemo product={withReducedPrice ? campaignProduct : product} cardVariant rightVariant />
          </PriceWrapper>
        )}
        <LinkDiscover
          route="product"
          params={campaignProduct}
          data-testid="product_card_button"
          onClick={internalOnClick}
        >
          {t(`common:product.section_product_card_link_discover`)}
        </LinkDiscover>
      </ProductElement>
    </Product>
  )
}

ProductRowCard.propTypes = {
  position: PropTypes.number,
  product: PropTypes.shape({
    originalId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    universDescription: PropTypes.string,
    impactProduct: PropTypes.shape({
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          standardPrice: PropTypes.number.isRequired,
          offerPrice: PropTypes.number,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    housingType: PropTypes.string.isRequired,
    universes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired, // for the event tracker
        slug: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  withLogo: PropTypes.bool,
  withPrice: PropTypes.bool,
  withUniverseDescription: PropTypes.bool,
  withReducedPrice: PropTypes.bool,
  universesColor: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
