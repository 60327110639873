import React from "react"
import Proptypes from "prop-types"
import { HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON, HOUSINGTYPE_MIXTE } from "./product.utils"
import { Bold } from "../structure/theme/styles.component"
import House from "../../components/pictos/house.component"
import Flat from "../../components/pictos/flat.component"

export default function ProductTypePicto ({ type, color = `white`, size = 24 }) {
  switch (type) {
    case HOUSINGTYPE_APPARTEMENT:
      return <Flat color={color} size={size} />
    case HOUSINGTYPE_MAISON:
      return <House color={color} size={size} />
    case HOUSINGTYPE_MIXTE:
      return (
        <>
          <House color={color} size={size} />
          <Bold>+</Bold>
          <Flat color={color} size={size} />
        </>
      )
    default:
      return null
  }
}

ProductTypePicto.propTypes = {
  type: Proptypes.oneOf([HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON, HOUSINGTYPE_MIXTE, null]),
  color: Proptypes.bool,
  size: Proptypes.oneOfType([Proptypes.number, Proptypes.string]),
}
