import styled from "styled-components"
import { fontSizes } from "../structure/theme"
import { flexCenter } from "../structure/theme/styles.component"

export const Title = styled.h3`
  text-align: center;
  font-weight: 900;
  font-size: ${fontSizes(`large`)};
  line-height: 1.4;
  margin-top: 20px;
  width: 100%;
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  /* stylelint-enable value-no-vendor-prefix */
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable property-no-vendor-prefix */
  overflow: hidden;
  text-overflow: ellipsis;
  height: calc(2 * 1.4 * ${fontSizes(`large`)}); /* Fallback for non-webkit */
  margin-bottom: 10px;
`

export const Promo = styled.div`
  width: 150px;
  height: 32px;
  border-radius: 2px;
  font-weight: 900;
  color: white;
  ${flexCenter};
  background-color: ${props => props.theme.modules.product.card.specialOffer};
`
