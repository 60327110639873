import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { colors, modules, percent } from "../structure/theme"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import ProductColumnCard, { Description, Half, Product } from "./productColumnCard.component"
import ProductRowCard from "./productRowCard.component"
import { StyledRichContent } from "../structure/theme/richContent.component"
import { ProductHead } from "./housingType.component"
import _noop from "lodash/noop"

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  ${breakpoint(`medium`)`
    ${props =>
      props.nbProducts === 1 &&
      `
      padding-left: 16%;
      padding-right: 16%;
    `}
    ${props =>
      props.nbProducts === 2 &&
      `
      padding-left: 8%;
      padding-right: 8%;
    `}
  `}
`

const CardColumnContainer = styled.div`
  padding: 15px;
  margin: 0;
  width: 100%;
  
  ${breakpoint(`medium`)`
    ${Description} {
      ${props =>
        props.nbProducts >= 4 &&
        `
        ul li {
          font-size: ${props.theme.fonts.sizes.small};
        }
      `}
    }
  `}
  
  ${breakpoint(`small`)`
    :only-of-type ${Product} {
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 0;
      
      ${Half} {
        width: 50%;
        height: 100%;
        
        &:first-of-type {
          padding-bottom: 20px;
        }
        
        &:last-of-type {
          padding-bottom: 20px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: ${modules(`product.card.description.backgroundColor`)};
        }
      }
      
      ${StyledRichContent} {
        display: flex;
        align-items: center;
      }
      
      ${ProductHead} {
        display: none;
      }
      
      ${Description} {
        border-top: 0;
        margin-top: 20px;
        padding-bottom: 0;
        border-left: 1px solid ${colors(`grey.g600`)};
        border-bottom-left-radius: 0;
      }
    }
  `}
  
  ${breakpoint(`small`)`
    width: ${props => {
      switch (props.nbProducts) {
        case 3:
          return percent(1 / 2)
        case 4:
          return percent(2 / props.nbProducts)
        default:
          return percent(1 / props.nbProducts)
      }
    }};
  `}
  
  ${breakpoint(`medium`)`
    width: ${props => percent(1 / props.nbProducts)};
  `}
`

const CardRowContainer = styled.div`
  padding: 0 0 15px;
  width: 100%;
`

const THRESHOLD_ROW_FORMAT = 5

export default function ProductBlock ({
  products,
  withLogo = false,
  withPrice = false,
  withUniverseDescription = false,
  seoPositionOffset = 0,
  withReducedPrice = false,
  withMoreInfo,
  universesColor = false,
  onClick = _noop,
}) {
  const nbProducts = products.length

  const singleHousing = useMemo(() => {
    const orderByHousingType = {}
    products.forEach(({ housingType }) => {
      if (housingType in orderByHousingType) {
        orderByHousingType[housingType]++
      } else {
        orderByHousingType[housingType] = 1
      }
    })

    return Object.keys(orderByHousingType).length <= 1
  }, [products])

  if (!nbProducts) {
    return null
  }

  const [Card, CardContainer] =
    nbProducts >= THRESHOLD_ROW_FORMAT ? [ProductRowCard, CardRowContainer] : [ProductColumnCard, CardColumnContainer]

  return (
    <Container nbProducts={nbProducts}>
      {products.map((product, position) => (
        <CardContainer key={product.id} nbProducts={nbProducts}>
          <Card
            product={product}
            withLogo={withLogo}
            withPrice={withPrice}
            withUniverseDescription={withUniverseDescription}
            withReducedPrice={withReducedPrice}
            withMoreInfo={withMoreInfo}
            withHousingTypeLabel
            singleHousing={singleHousing}
            position={position + 1 + seoPositionOffset}
            adaptativeDescription={nbProducts !== THRESHOLD_ROW_FORMAT - 1}
            universesColor={universesColor}
            onClick={event => onClick(event, product)}
          />
        </CardContainer>
      ))}
    </Container>
  )
}

ProductBlock.propTypes = {
  seoPositionOffset: PropTypes.number,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  withLogo: PropTypes.bool,
  withPrice: PropTypes.bool,
  withUniverseDescription: PropTypes.bool,
  withReducedPrice: PropTypes.bool,
  withMoreInfo: PropTypes.bool,
  universesColor: PropTypes.bool,
  onClick: PropTypes.func,
}
