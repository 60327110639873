import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle } from "styled-components"
import HousingType from "./housingType.component"
import { colors, condition, modules } from "../structure/theme"
import { ProductPriceMemo } from "./productPrice.component"
import { ButtonLink } from "../structure/theme/button.component"
import breakpoint from "styled-components-breakpoint"
import { ProductPictoMemo } from "./productPicto.component"
import { useCampaignProduct } from "../campaign/campaign.utils"
import RichContent from "../structure/theme/richContent.component"
import { useProductClickTracking } from "../thirdParties/gtm/tagManager"
import { usePrice } from "./product.utils"
import { Promo as StyledPromo, Title } from "./productCard.styled"
import { useTranslation } from "../translation/translate.component"

const LinkDiscover = styled(ButtonLink).attrs({
  textColor: modules(`product.card.button.color`),
  backgroundColor: modules(`product.card.button.backgroundColor`),
  major: true,
})`
  max-width: calc(100% - 20px);
  width: 300px;
  display: inline-flex;
  text-transform: none;
  margin-top: 25px;

  ${breakpoint(`small`)`
    width: 200px;
  `}
`

const LinkMoreInfo = styled(ButtonLink).attrs(() => ({
  textColor: modules(`product.card.description.backgroundColor`),
  backgroundColor: modules(`product.card.buttonMoreInfos.color`),
  ghost: true,
}))`
  text-transform: none;
  width: 300px;
  display: inline-flex;
  max-width: calc(100%);
  margin: 24px auto 0;

  ${breakpoint(`small`)`
    width: 200px;
  `}
`

export const Description = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 20px;
  color: ${modules(`product.card.description.color`)};
  align-content: space-between;
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: ${props => (props.adaptativeDescription ? `1 1 auto` : `0`)};
  background-color: ${modules(`product.card.description.backgroundColor`)};
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;

  ${breakpoint(`small`)`
    margin-top: 26px;
    padding: 20px;
    border-top: 1px solid ${colors(`grey.g600`)};
    flex: 1;
  `}
`

const GlobalStyle = createGlobalStyle`
  /* css trick to target only ie11 and fix footer on top */
  /* stylelint-disable-next-line selector-type-no-unknown */
  _:-ms-fullscreen, :root #___gatsby > div { 
    ${Description} {
      display: block;
    }
  }
`

export const Product = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 8px;
  align-items: center;
  height: 100%;

  ${breakpoint(`small`)`
    flex: 1;
  `}
`

const Promo = styled(StyledPromo)`
  position: absolute;
  top: ${condition(`singleHousing`, -5, -16)}px;
  left: 50%;
  transform: translateX(-50%);
`

const PictoWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`

const SeeMore = styled.div`
  border-top: 1px solid ${colors(`grey.g600`)};
  width: 100%;
  padding: 0 16px;
  font-weight: 900;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: ${condition(`open`, `flex-end`, `space-between`)};
  margin-top: ${condition(`open`, `20px`)};

  &:hover {
    cursor: pointer;
  }

  ${breakpoint(`small`)`
    display: none;
  `}
`

const PriceWrapper = styled.div`
  display: flex;
`

const ToggleResponsive = styled.div`
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
  height: ${condition(`open`, `auto`, `0`)};
  padding-top: ${condition(`open`, `20px`, `0`)};
  margin: auto;
  width: 100%;

  ${breakpoint(`small`)`
    height: auto;
    padding-left: 0;
    padding-right: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
  `}
`

export const Half = styled.div`
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint(`small`)`
    height: auto;

    &:last-of-type {
      flex: 1;
      height: 100%;
    }
  `}
`

export default function ProductColumnCard ({
  product,
  withLogo = false,
  withPrice = false,
  withUniverseDescription = false,
  withReducedPrice = false,
  withMoreInfo = true,
  adaptativeDescription = true,
  position = 0,
  singleHousing = false,
  onClick,
}) {
  const { t } = useTranslation()
  const [campaignProduct] = useCampaignProduct(product)
  const [responsiveOpen, setResponsiveOpen] = useState(false)

  const trackProductClick = useProductClickTracking(product, position)

  const { name, slug, universes, universDescription, housingType } = product

  const { betterPrice } = usePrice(campaignProduct)

  const toggleResponsive = () => setResponsiveOpen(value => !value)

  function internalOnClick (event) {
    trackProductClick()
    onClick(event, product)
  }

  return (
    <Product
      data-testid={`${slug
        .split(`/`)
        .slice(-2, -1)[0]
        .replace(/-/g, `_`)}_product_card`}
      adaptativeDescription={adaptativeDescription}
    >
      <GlobalStyle />
      <Half>
        <HousingType type={housingType} singleHousing={singleHousing} />
        {withPrice && withReducedPrice && betterPrice && (
          <Promo singleHousing={singleHousing} data-testid="special_offer">
            {t(`common:product.section_product_card_special_offer`)}
          </Promo>
        )}
        {withLogo && (
          <PictoWrapper>
            <ProductPictoMemo universes={universes} data-testid="product_card_logo" size={70} card />
          </PictoWrapper>
        )}
        <Title data-testid="product_card_name">{name}</Title>
        {withPrice && (
          <PriceWrapper>
            <ProductPriceMemo product={withReducedPrice ? campaignProduct : product} cardVariant />
          </PriceWrapper>
        )}
        <LinkDiscover
          route="product"
          params={campaignProduct}
          data-testid="product_card_button"
          onClick={internalOnClick}
        >
          {t(`common:product.section_product_card_link_discover`)}
        </LinkDiscover>
      </Half>
      {withUniverseDescription && (
        <Half>
          <Description adaptativeDescription={adaptativeDescription}>
            <ToggleResponsive open={responsiveOpen}>
              <RichContent
                content={universDescription}
                styledBullets
                fullBullets
                data-testid="product_card_description"
              />
              {withMoreInfo && (
                <LinkMoreInfo
                  data-testid="product_card_more_infos"
                  onClick={internalOnClick}
                  route="product"
                  params={campaignProduct}
                >
                  {t(`common:product.section_product_card_link_more_information`)}
                </LinkMoreInfo>
              )}
            </ToggleResponsive>
            <SeeMore onClick={toggleResponsive} open={responsiveOpen}>
              {!responsiveOpen && <span>{t(`common:product.section_product_card_button_open_modal`)}</span>}
              <span>{responsiveOpen ? `-` : `+`}</span>
            </SeeMore>
          </Description>
        </Half>
      )}
    </Product>
  )
}

ProductColumnCard.propTypes = {
  position: PropTypes.number,
  product: PropTypes.shape({
    originalId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    universDescription: PropTypes.string,
    impactProduct: PropTypes.shape({
      variants: PropTypes.arrayOf(
        PropTypes.shape({
          standardPrice: PropTypes.number.isRequired,
          offerPrice: PropTypes.number,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    housingType: PropTypes.string.isRequired,
    universes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired, // for the event tracker
        slug: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  withLogo: PropTypes.bool,
  withPrice: PropTypes.bool,
  withUniverseDescription: PropTypes.bool,
  withReducedPrice: PropTypes.bool,
  withMoreInfo: PropTypes.bool,
  adaptativeDescription: PropTypes.bool,
  singleHousing: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
