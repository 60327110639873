import React from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import { HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON, HOUSINGTYPE_MIXTE } from "./product.utils"
import { Bold, flexCenter } from "../structure/theme/styles.component"
import lighten from "polished/lib/color/lighten"
import { condition, fontSizes } from "../structure/theme"
import breakpoint from "styled-components-breakpoint"
import { Trans, useTranslation } from "../translation/translate.component"
import ProductTypePicto from "./productTypePicto.component"

const colorFromType = (type, modules) => {
  switch (type) {
    case HOUSINGTYPE_APPARTEMENT:
      return lighten(0.1, modules.product.card.housingType.backgroundColor)
    case HOUSINGTYPE_MAISON:
      return lighten(0.05, modules.product.card.housingType.backgroundColor)
    case HOUSINGTYPE_MIXTE:
      return modules.product.card.housingType.backgroundColor
    default:
      throw new Error(`HousingType ${type} not valid`)
  }
}

export const ProductHead = styled.div`
  position: relative;
  color: white;
  background-color: ${props => colorFromType(props.$type, props.theme.modules)};
  height: ${condition(`singleHousing`, 40, 60)}px;
  ${flexCenter};
  font-size: ${fontSizes(`large`)};
  line-height: ${fontSizes(`regular`)};
  width: 100%;
  margin-bottom: 15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: 900;
  padding-top: 5px;

  > span {
    text-align: center;
  }

  ${breakpoint(`small`)`
    font-weight: 500;
  `}
`
const ProductTitle = styled.span`
  align-items: center;
  display: flex;
  > * {
    margin-left: 8px;
  }
`

const Svg = styled.svg`
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  height: 15px;
  display: block;

  path {
    fill: ${props => colorFromType(props.$type, props.theme.modules)};
  }
`

export default function HousingType ({ type, singleHousing = false }) {
  if (!type) {
    return null
  }
  const { t } = useTranslation()

  return (
    <ProductHead $type={type} singleHousing={singleHousing} data-testid="product_card_housingType">
      {!singleHousing && (
        <ProductTitle>
          <div>
            {type !== HOUSINGTYPE_MIXTE && (
              <Trans
                t={t}
                i18nKey="common:product.section_product_card_housing_type_contract_type"
                components={{
                  bold: <Bold />,
                }}
                values={{ type }}
              />
            )}
          </div>
          <ProductTypePicto type={type} />
        </ProductTitle>
      )}
      <Svg $type={type} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 2 1">
        <g>
          <path d="M0,0 l2,0 l-1,1 z" />
        </g>
      </Svg>
    </ProductHead>
  )
}

HousingType.propTypes = {
  type: Proptypes.oneOf([HOUSINGTYPE_APPARTEMENT, HOUSINGTYPE_MAISON, HOUSINGTYPE_MIXTE, null]),
  singleHousing: Proptypes.bool,
}
