import React from "react"
import styled from "styled-components"
import Proptypes from "prop-types"
import { colors } from "../theme"
import cssThinChecked from "../../../components/pictos/cssThinChecked"

export const StyledRichContent = styled.div`
  flex: 1;

  strong,
  b {
    font-weight: 900;
  }
  ${props =>
    props.styledBullets &&
    `
  ul {
    list-style: none;
    padding: 0px 0px 8px 0px;
    li {
      padding-left: 40px;
      margin-bottom: 10px;
      position: relative;
      font-size: ${props.theme.fonts.sizes.regular};
      color: ${props.color1 || colors(`black`)};

      &::before {
        content: "";
        border: 1px solid ${props.color1 || props.theme.modules.product.richDescription.bullet.color};
        background-color: ${props.color2 ||
          (props.fullBullets ? props.theme.modules.product.richDescription.bullet.color : `white`)};
        background-image: url("${cssThinChecked(
          26,
          26,
          props.color1 || (props.fullBullets ? `white` : props.theme.modules.product.richDescription.bullet.color),
        )}");
        background-repeat: no-repeat;
        background-position: center center;
        color: ${props.theme.modules.product.richDescription.bullet.color};
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 1px;
        width: 23px;
        height: 23px;
      }
    }
  }
  `}

  ${props =>
    props.revertMode &&
    `
    background-color: white;
    padding: 50px;
  
    p:first-child {
      color: ${props.theme.colors.accent};
      text-transform: uppercase;
      font-size: ${props.theme.fonts.sizes.large};
      margin-top: 0;
      margin-bottom: ${props.theme.fonts.sizes.regular};
      font-weight: 700;
    }
  `}
`

export default function RichContent ({
  content,
  styledBullets = false,
  fullBullets = false,
  revertMode = false,
  color1 = ``,
  color2 = ``,
}) {
  return (
    <StyledRichContent
      styledBullets={styledBullets}
      fullBullets={fullBullets}
      revertMode={revertMode}
      color1={color1}
      color2={color2}
      data-testid={arguments[0][`data-testid`] || undefined}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  )
}
RichContent.propTypes = {
  content: Proptypes.node.isRequired,
  styledBullets: Proptypes.bool,
  fullBullets: Proptypes.bool,
  revertMode: Proptypes.bool,
  color1: Proptypes.string,
  color2: Proptypes.string,
}
